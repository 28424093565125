<template>
  <div>
    <div
      class="header pb-6 pt-5 pt-lg-8 d-flex align-items-center profile-header"
      style="
        min-height: 150px;
        background-size: cover;
        background-position: center top;
      "
    >
      <b-container fluid>
        <!-- Mask -->
        <span style="background-color: #8f384d" class="mask opacity-8"></span>
        <!-- Header container -->
        <b-container fluid class="d-flex align-items-center"> </b-container>
      </b-container>
    </div>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col></b-col>
        <b-col sm="9" xl="7">
          <card>
            <!-- <object
              width="100%"
              height="500px"
              data="https://surveys.hotjar.com/e0d6c9fe-8079-4da1-9bc4-e29571393458"
            ></object> -->
            <embed src="https://surveys.hotjar.com/e0d6c9fe-8079-4da1-9bc4-e29571393458">
          </card>
          <!-- <card>
            <b-row>
              <b-col class="text-center mb-4">
                มาร่วมลงชื่อส่งพลังความต้องการของทุกคนให้ถึงทีมนักพัฒนา
                <i class="ni ni-favourite-28 text-orange"></i>
                <br />
                ผลักดันให้เกิด ENGenius เต็มรูปแบบ ที่จะเต็มไปด้วย <br />
                <h4></h4>
                <ul class="text-left ml-4">
                  <li>ข้อสอบ full option ในทุกวิชา</li>
                  <li>ฟังก์ชันการทำข้อสอบแบบสุ่มข้อ</li>
                  <li>
                    ฟังก์ชันการจำลองสอบจริง<br />(เลือกวิชาจริง,สุ่มข้อจริง,จับเวลาจริง,นับคะแนนจริง)
                  </li>
                  <li>ฟังก์ชันเก็บสถิติเพื่อสะท้อนการฝึกฝน</li>
                  <li>ฟังก์ชันการจดจำเพื่อเรียนรู้ข้อสอบอย่างรวดเร็ว</li>
                </ul>
                เราจะพาทุกคนสอบผ่านไปด้วยกัน
                <i class="ni ni-satisfied" style="color: #ffc75f"></i>
              </b-col>
            </b-row>
            <b-alert
              :show="dismissCountDown"
              dismissible
              :variant="msgtype"
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChanged"
              >{{ msgAlert }}</b-alert
            >
            <div>
              <b-form @submit="onSubmit">
                <b-form-group
                  id="input-group-1"
                  label="ความพึงพอใจ:"
                  label-for="input-1"
                >
                </b-form-group>
                <b-form-rating
                  v-model="form.star"
                  for="rating-lg"
                  variant="warning"
                  class="mb-2"
                ></b-form-rating>

                <b-form-group
                  id="input-group-2"
                  label="ชื่อ-นามกุล:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.name"
                    placeholder="ดีใจ ใจดี"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Email:"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.email"
                    type="email"
                    placeholder="gg@gmail.com"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="เพิ่มเติม:"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.addition"
                    placeholder=""
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-4">
                  <b-form-checkbox v-model="form.receive"
                    >รับข่าวสารจากเรา</b-form-checkbox
                  >
                </b-form-group>
                <b-button type="submit" variant="primary" size="lg"
                  >ตกลง</b-button
                >
              </b-form>
            </div>
          </card> -->
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msgtype: "danger",
      msgAlert: "",
      dismissSecs: 5,
      dismissCountDown: 0,
      form: {
        email: "",
        name: "",
        addition: "",
        receive: true,
        star: 3,
      },
    };
  },
  methods: {
    onSubmit(event) {
      //   event.preventDefault();
      //   var e = this;
      //   this.axios
      //     .post(this.$store.state.api + "php/api.php?tb=tb_fund", e.form)
      //     .then((response) => {
      //       console.log(response.data);
      //       e.showAlert(
      //         "ขอบคุณสำหรับการสนับสนุน ระบบกำลังไปยัง Facebook Page...",
      //         "success"
      //       );
      //       setTimeout(function () {
      //         window.location.replace("https://www.facebook.com/EnGeniusTH");
      //       }, 5000);
      //     });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(msg, type) {
      this.msgtype = type == null ? "danger" : type;
      this.msgAlert = msg;
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>
<style>
</style>
